.nav {
    margin-top: $space / 3;

    a {
        font-family: $regular;
        margin-left: $space;

        &:hover {
            opacity: 0.8;
        }
    }

    .signup {
        border-radius: 8px;
        color: white;
        background: $secondary-color;
        padding: $space / 4 $space / 2;

        &:hover {
            opacity: 0.8;
        }
    }
}