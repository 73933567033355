.footer {
    text-transform: uppercase;
    margin-bottom: $space * 2;

    a {
        display: block;
        font-family: $regular;
        color: $footer-color;

        @media (min-width: $sm-screen) {
            display: inline-block;
            margin-left: $space;
        }
    }

    .imprint {
        color: $footer-color;
        text-align: left;

        a {
            &:hover {
                color: $primary-color;
            }
        }

        @media (min-width: $sm-screen) {
            text-align: center;
        }
    }

    @media (min-width: $sm-screen) {
        margin-top: $space * 4;
    }
}
