body {
    font-family: $regular;
    font-size: 15px;
    line-height: 25px;
    color: $primary-color;
    margin: 0;
}

h1 {
    font-family: $medium;
    font-weight: 500;
    font-size: 3rem;
    line-height: 4rem;
    letter-spacing: -0.7px;
    margin-bottom: $space;

    @media (min-width: $md-screen) {
        font-size: 3.5rem;
    }

    @media (min-width: $lg-screen) {
        font-size: 4.5rem;
        line-height: 5rem;
    }
}

h2 {
    font-family: $medium;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3rem;
    margin: $space / 2 0;

    @media (min-width: $md-screen) {
        font-size: 3rem;
        line-height: 4rem;
        margin: $space 0;
    }

    @media (min-width: $lg-screen) {
        font-size: 3.3rem;
        line-height: 4rem;
        margin: $space 0;
    }
}

h3 {
    font-size: 1.2rem;
    margin-bottom: 0;

    @media (min-width: $md-screen) {
        font-size: 1.5rem;
    }
}

h4 {
    font-size: 1.2rem;
    margin-bottom: 0;
}

a {
    font-family: $bold;
    font-weight: 700;
    color: $primary-color;
    text-decoration: none;

    &:hover {
        opacity: 0.8;
    }
}

strong {
    font-family: $bold;
    font-weight: 700;
}

.right {
    text-align: right;
}

.space {
    &--top {
        @media (min-width: $md-screen) {
            margin-top: $space*3;
        }
    }
}

.wrapper {
    width: 100%;
    margin: auto;

    @media (min-width: $lg-screen) {
        width: 80%;
    }
}

.hide-xs {
    display: none;

    @media (min-width: $sm-screen) {
        display: inline-block;
    }
}

.visible-xs {
    @media (min-width: $sm-screen) {
        display: none;
    }
}
