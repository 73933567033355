$primary-color: #2d2d2d;
$secondary-color: #6070d4;
$section-color: #f8f8f6;
$footer-color: #9B9B9B;
$space: 30px;
$xs-screen: 576px;
$sm-screen: 768px;
$md-screen: 992px;
$lg-screen: 1200px;
$xlg-screen: 1600px;
