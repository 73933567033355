.button {
    display: inline-block;
    border-radius: 8px;
    background: $secondary-color;
    font-family: $bold;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    padding: $space / 2 $space * 2;
    margin-top: $space / 2;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}