.logo {
    height: 40px;
}

.header {
    width: 100%;
    background: white;
    position: fixed;
    top: 0;
    z-index: 5;
}
