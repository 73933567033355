.homepage {
    p {
        font-size: 1.1rem;
        line-height: 2rem;

        @media (min-width: $lg-screen) {
            font-size: 1.3rem;
        }
    }
    
    .section {
        margin-bottom: $space * 2;

        &:nth-child(even) {
            background: $section-color;
            padding-bottom: ($space*2);
            
            @media (min-width: $sm-screen) {
                padding-top: ($space*2);
            }
        }

        &.welcome {
            &:nth-child(even) {
                padding: ($space*2) 0;
            }
        }
    }

    .welcome {
        margin-top: $space * 2.5;
        margin-bottom: 0;

        .phone-holder {
            position: relative;

            @media (min-width: $sm-screen) {
                position: absolute;
            }
        }

        @media (min-width: $sm-screen) {
            margin-bottom: $space * 7;
        }
    }
    
    .phone-holder {
        position: relative;
        top: 0;
        width: 100%;
        z-index: 2;

        @media (min-width: $xlg-screen) {
            width: 75%;
        }
    }

    .phone {
        position: relative;
        width: 100%;
        background: none;
        
        @media (min-width: $sm-screen) {
            height: 551px;
            background: url(../img/homepage/iphone_mask.png);
            background-repeat: no-repeat;
            background-size: 100%;
        }

        @media (min-width: $md-screen) {
            height: 705px;
        }

        img,
        video {
            width: 100%;

            @media (min-width: $sm-screen) {
                position: absolute;
                left: 33px;
                top: 26px;
                width: 55%;
                z-index: -1;
            }

            @media (min-width: $md-screen) {
                left: 40px;
                top: 33px;
            }
        }
    }

    .download {
        &__rating {
            width: 80%;
            margin-bottom: $space / 2;

            @media (min-width: $sm-screen) {
                width: 70%;
            }
        }

        &__icon {
            width: 50%;
            margin-top: $space / 2;

            @media (min-width: $sm-screen) {
                width: 25%;
            }

            @media (min-width: $lg-screen) {
                width: 15%;
            }
        }
    }
}
